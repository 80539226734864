.product-page {
    padding: 40px 0;
    background: var(--bg-white);
}

.product-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
}

.product-photo img {
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-info {
    flex: 1;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-info h2 {
    font-size: 2rem;
    color: var(--color-blue-dark);
    margin-bottom: 15px;
    font-family: var(--font-family--poppins);
}

.product-description {
    font-size: 1rem;
    color: var(--color-blue-dark);
    margin-bottom: 20px;
}

.product-detail-description div,
.product-detail-description ul,
.product-detail-description li,
.product-detail-description p,
.product-detail-description h1,
.product-detail-description h2,
.product-detail-description h3,
.product-detail-description h4,
.product-detail-description h5,
.product-detail-description h6,
.product-detail-description p {
    font-size: auto;
    font-family: var(--font-family--poppins) !important;
    background: inherit;
    background-color: inherit !important;
}

.product-detail-description p,
.product-detail-description li {
    font-size: 1rem !important;
}

.product-pricing {
    margin-bottom: 20px;
}

.product-pricing p {
    font-size: 1.2rem;
    color: var(--color-blue-dark);
    margin: 5px 0;
}

.product-pricing .mrp {
    font-size: 2.2rem;
    color: var(--color-marron);
}

.product-pricing .mrp span {
    font-size: 1.2rem;
    text-decoration: line-through;
    color: var(--color-parrot);
}

.product-pricing .selling-price {
    font-weight: bold;
}

.product-specs {
    margin-bottom: 20px;
}

.product-specs p {
    font-size: 1rem;
    color: var(--color-blue-dark);
    margin: 5px 0;
}

/* Add this CSS to style the quantity buttons */
.quantity-selector {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.quantity-selector label {
    font-size: 1rem;
    color: var(--color-blue-dark);
    margin-bottom: 5px;
}

.quantity-selector input {
    width: 60px;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid var(--color-blue-dark);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    outline: none;
}

.quantity-selector input::-webkit-outer-spin-button,
.quantity-selector input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.quantity-selector input[type=number] {
    -moz-appearance: textfield;
}

.quantity-selector .quantity-btn {
    padding: 15px 20px;
    border: 1px solid var(--color-blue-dark);
    border-radius: 5px;
    background: var(--color-blue-dark);
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.quantity-selector .quantity-btn:hover {
    background-color: var(--color-orange);
    border-color: var(--color-blue);
}

.quantity-selector .quantity-btn:active {
    background-color: var(--color-marron);
    color: white;
}


.product-actions {
    display: flex;
    gap: 10px;
}

.buy-now-btn,
.add-to-cart-btn {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    transition: background-color var(--transition-3);
}

.buy-now-btn {
    background-color: var(--color-orange);
}

.add-to-cart-btn {
    background-color: var(--color-carnation-pink);
}

.buy-now-btn:hover {
    background-color: darkorange;
}

.add-to-cart-btn:hover {
    background-color: var(--color-button-hover);
}

.related-products {
    margin-top: 40px;
}

.related-products h2 {
    font-size: 1.8rem;
    color: var(--color-blue-dark);
    margin-bottom: 20px;
    font-family: var(--font-family--poppins);
}



















/* .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
} */

.related-product-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 20px;
    text-align: center;
    padding: 10px;
}

.related-product-card img {
    width: 100%;
    height: 220px;
    object-fit: contain;
    border-bottom: 1px solid var(--border-color-1);
}

.related-product-card .product-details {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.related-product-card h3 {
    font-size: 1.2rem;
    color: var(--color-blue-dark);
    margin-bottom: 10px;
}

.view-details-btn {
    padding: 8px 16px;
    background-color: var(--color-carnation-pink);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color var(--transition-3);
    text-decoration: none;
}

.view-details-btn:hover {
    background-color: var(--color-button-hover);
}






















/* Color Circles */
.variant-selector label {
    color: var(--color-blue-dark);
    font-size: 1.2rem;
}

.color-selector {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 1rem;
}

.color-options {
    display: flex;
    gap: 10px;
}

.color-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid var(--border-color-1);
    cursor: pointer;
}

.color-circle.selected {
    border: 2px solid var(--color-orange);
}

/* Size Boxes */
.size-selector {
    display: flex;
    gap: 10px;
    align-items: center;
}

.size-options {
    display: flex;
    gap: 10px;
}

.size-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--border-color-1);
    cursor: pointer;
    border-radius: 5px;
    outline: none;
    background-color: var(--bg-white);
    color: var(--color-blue-dark);
    padding: 0.5rem 1rem;
}

.size-box.selected {
    border: 2px solid var(--color-carnation-pink);
    background-color: var(--color-pink-light);
}

.size-box:hover {
    background-color: var(--color-pink);
}





/* Popup Styling */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    animation: fadeIn 0.3s ease-out;
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.8rem;
    color: var(--color-red);
    cursor: pointer;
    transition: color 0.2s;
}

.popup-close:hover {
    color: #555;
}

.popup-header {
    display: flex;
    align-items: self-end;
    gap: 15px;
    margin-bottom: 15px;
}

.popup-overlay .popup-header h2 {
    color: var(--color-red);
    font-size: 1.6rem;
    flex-grow: 1;
}

.product-image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
}

.popup-body {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.popup-body p {
    margin-bottom: 10px;
}
.popup-body p strong {
    color: var(--color-blue-dark);
    font-weight: 500;
    text-transform: uppercase;
}

.popup-overlay .product-actions button {
    font-size: 1rem;
    padding: 0.5rem 0rem;
}


/* Mobile Responsiveness */
@media (max-width: 768px) {
    .popup-content {
        max-width: 95%;
    }
    
    .popup-header h2 {
        font-size: 1.4rem;
    }
    
    .popup-body {
        font-size: 0.9rem;
    }
    
    .popup-btn {
        font-size: 0.9rem;
    }
}
@media (max-width: 420px){
    .popup-overlay .product-actions button {
        font-size: 0.8rem;
        padding: 0.5rem 0rem;
    }
}

/* Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.95);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}






















/* ==== Product By Category ===  */
.show-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: var(--color-carnation-pink);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.3rem;
}

.show-more-button:hover {
    background-color: var(--color-marron);
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--color-marron);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}