.confirm-order-section {
    background-color: var(--bg-white);
    padding: 40px 0;
}

.confirm-order-section h2 {
    font-size: 2rem;
    color: var(--color-carnation-pink);
}

.confirm-order-section p {
    font-size: 1rem;
    color: var(--color-marron);
}

.checkout-order-summary, .payment-details {
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    border: 1px solid var(--color-carnation-pink);
}

.checkout-order-summary p, .payment-details p {
    font-size: 1rem;
    font-weight: bold;
}

.checkout-order-summary p span, .payment-details p span {
    float: right;
    color: var(--color-orange);
}

.btn-primary, .btn-secondary {
    margin: 10px;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
}

.btn-primary {
    background-color: var(--color-parrot) !important;
    border: none !important;
}

.btn-secondary {
    background-color: var(--color-pink-light) !important;
    border: none !important;
}

.btn-primary:hover, .btn-secondary:hover {
    opacity: 0.8;
}
