/* Newsletter Section */
.newsletter-section {
    background-color: var(--bg-yellow);
    color: #fff;
    padding: 40px 20px;
    display: none;
}

.newsletter-section h3 {
    margin-bottom: 20px;
    font-size: 1.75rem;
    color: #003873;
}

.newsletter-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.newsletter-form input[type="email"] {
    padding: 10px;
    border: none;
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
    outline: 1px solid var(--bg-yellow);
}

.newsletter-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #003873;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
    background-color: #002954;
}

/* Footer Section */
.footer {
    background-color: #003873;
    color: #fff;
    padding: 40px 0 40px; /* Adjusted for overlap with newsletter */
}

.footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
    /* background: white; */
}

.footer-section {
    margin-bottom: 20px;
}

.footer-section h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #00AAA9;
}

.footer-section p, .footer-section ul {
    margin: 0;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-section ul li a:hover {
    color: #00AAA9;
}

.social-icons {
    display: flex;
    gap: 15px;
}

.social-icons a {
    color: #fff;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    color: #00AAA9;
}

.footer-bottom {
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid #fff;
}

.footer-bottom p {
    margin: 0;
    font-size: 0.975rem;
}

.footer-bottom a {
    color: #00AAA9;
    text-decoration: none;
}

.footer-bottom a:hover {
    text-decoration: underline;
}


@media only screen and (max-width: 450px){
    .footer-logo {
        max-width: 120px;
        object-fit: contain;
    }
}
