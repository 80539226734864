.blog-section {
    background: #f9f9f9;
    background: var(--bg-yellow);
    padding: 60px 0;
}

.section-title {
    font-size: 2.5rem;
    color: var(--color-marron);
    margin-bottom: 40px;
}

.blog-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 30px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.blog-content {
    padding: 20px;
}

.blog-title {
    font-size: 1.5rem;
    color: var(--color-marron);
    margin-bottom: 15px;
}

.blog-summary {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
}

.blog-meta {
    font-family: var(--font-family--poppins);
    font-size: 0.975rem;
    color: var(--color-marron);
    margin-bottom: 15px;
}

.blog-author,
.blog-date {
    font-weight: bold;
}

.read-more-btn {
    display: inline-block;
    padding: 10px 20px;
    background: var(--color-light-sky-blue);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-decoration: none;
}

.read-more-btn:hover {
    background: var(--color-uranian-blue);
}

.view-all-btn {
    display: inline-block;
    padding: 15px 30px;
    background: #4377a2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 20px;
    text-decoration: none;
}

.view-all-btn:hover {
    background: #365a80;
}
