/* General styles for sidebar and toggle button */
.cart-sidebar {
    position: fixed;
    right: -100%; 
    top: 0;
    width: 350px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.cart-sidebar.open {
    right: 0;
}
.cart-sidebar .cart-head{
    border-bottom: 1px solid #9b59b6;
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
}
.close-btn {
    /* align-self: flex-end; */
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

/* Cart content styles */
.cart-content h2 {
    margin-bottom: 20px;
}

.cart-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 1rem;
}

.cart-item img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}

.item-details {
    display: flex;
    flex-direction: column;
}

.item-details p {
    margin: 0;
    font-size: 14px;
}

.quantity-selector {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.quantity-selector button {
    width: 30px;
    height: 30px;
    background: #9b59b6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantity-selector input {
    width: 50px;
    height: 30px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    margin: 0 10px;
}

/* Checkout section styles */
.cart-summary {
    margin-top: auto;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.cart-summary p {
    margin: 10px 0;
}

.subtotal {
    font-weight: bold;
    font-size: 18px;
}

.checkout-btn {
    width: 100%;
    padding: 10px;
    background: #9b59b6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}
