.cart-page{
    background: var(--bg-yellow);
    font-family: var(--font-family--poppins);
}
.cart-page .cart-items {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid var(--color-light-blue);
}

.cart-page .cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px;
    border: 1px solid var(--color-light-blue);
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.cart-page .cart-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(228, 225, 225, 0.1);
}

.cart-page .cart-item-image {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
}

.cart-page .cart-item-details {
    flex: 1;
    margin-left: 20px;
    color: var(--color-marron);
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.cart-page .cart-item-details h4 {
    margin: 0 0 8px;
    font-size: 1.2rem;
}

.cart-page .cart-item-details .item-price {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    color: var(--color-orange);
}

.cart-page .quantity-control {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.cart-page .quantity-control button {
    background-color: var(--color-light-sky-blue);
    color: #fff;
    border: none;
    padding: 5px 12px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cart-page .quantity-control button:hover {
    background-color: var(--color-uranian-blue);
}

.cart-page .quantity-control span {
    margin: 0 10px;
    font-size: 1rem;
    font-weight: bold;
}

.cart-page .remove-btn {
    background-color: var(--color-carnation-pink);
    color: #fff;
    border: none;
    padding: 6px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    margin-top: 10px;
}

.cart-page .remove-btn:hover {
    background-color: var(--color-marron);
}

.cart-page .cart-item-total {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--color-marron);
    text-align: right;
}

@media (max-width: 768px) {
    .cart-page .cart-item {
        align-items: center;
        justify-content: center;
    }

    .cart-page .cart-item-image {
        width: 100%;
        max-width: 120px;
        height: auto;
        margin-bottom: 15px;
    }
    .cart-page .cart-item-details {
        flex: 1;
        margin-left: 0px;
        color: var(--color-marron);
        display: flex;
        align-items: flex-start;
        gap: 0rem;
        justify-content: center;
        flex-direction: column;
    }

    .cart-page .cart-item-total {
        text-align: left;
        margin-top: 15px;
    }
}


.cart-page .cart-summary {
    padding: 20px;
    border: 1px solid var(--color-carnation-pink);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-page .cart-summary h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
}

.cart-page .coupon-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
}

.cart-page .coupon-section input {
    padding: 10px;
    border: 1px solid var(--color-carnation-pink);
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    outline: none;
}
.cart-page .coupon-section input::placeholder{
    color: var(--color-carnation-pink);
}

.cart-page .apply-coupon-btn {
    background: #28a745;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
}

.cart-page .apply-coupon-btn:hover {
    background: #218838;
}

.cart-page .checkout-btn {
    background: var(--color-marron);
    color: #fff;
    border: none;
    padding: 0.5rem 0.3rem;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: background 0.3s ease, transform 0.3s ease;
    text-align: center;
    display: block;
    text-decoration: none;
}

.cart-page .checkout-btn:hover {
    background: var(--color-orange);
}

