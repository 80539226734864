/* Testimonial.css */
.testimonial-container {
    background-color: var(--color-light-blue); /* Background color to match the design */
    padding: 50px 0;
}
.testimonial-container h2 {
    color: #003873; /* Dark blue color */
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}


.testimonial-section {
    text-align: center;
    margin: 0 auto;
    max-width: 1200px;
}

.testimonial {
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.testimonial:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial img {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: 15px;
    object-fit: cover;
}

.testimonial-profile {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.testimonial-text {
    font-size: 1rem;
    font-style: italic;
    margin-bottom: 20px;
    color: #333;
    position: relative;
    padding-left: 30px;
}

.testimonial-text::before {
    content: "“";
    font-size: 3rem;
    color: var(--color-light-blue);
    position: absolute;
    left: 0;
    top: -10px;
}

.testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #003873;
    margin-bottom: 0;
}

.testimonial-role {
    font-size: 0.9rem;
    color: #6c757d;
    margin-top: 5px;
}

/* Swiper specific styles */
.swiper-pagination-bullet {
    background: #003873;
}

.testimonial-container .swiper-button-next, .swiper-button-prev {
    color: #003873;
    opacity: 0.7;
    transition: opacity 0.3s;
    visibility: hidden;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
    opacity: 1;
}
