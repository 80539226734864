:root {
    --bg-white: #f7f9fe;
    --color-marron: #a05c7e;
    --color-orange: #f5812a;
    --bg-light: #f9f9f9;
    --color-text-dark: #333;
    --color-border: #ddd;
}

.breadcrumb-section {
    background-color: var(--bg-light);
    padding: 20px 0;
    border-bottom: 1px solid var(--color-border);
}

.breadcrumb-section h1 {
    margin-bottom: 0;
    font-size: 24px;
    color: var(--color-marron);
}

.breadcrumb-section .breadcrumb {
    background-color: transparent;
    padding: 0;
}

.checkout-section {
    padding: 30px 0;
}

.checkout-section h3 {
    margin-bottom: 20px;
    color: var(--color-marron);
    font-size: 20px;
}

.checkout-section .form-group {
    margin-bottom: 20px;
}

.checkout-section .form-control {
    border-radius: 5px;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid var(--color-border);
    color: var(--color-text-dark);
}

.checkout-section .form-control:focus {
    box-shadow: none;
    border-color: var(--color-marron);
}

.payment-options {
    background-color: var(--color-pink-light);
    padding: 15px;
    border-radius: 5px;
    border: 1px solid var(--color-border);
}

.payment-option {
    margin-bottom: 15px;
}

.payment-option input[type="radio"] {
    margin-right: 10px;
}

.payment-option label {
    font-size: 1rem;
    color: var(--color-text-dark);
}

.order-summary {
    margin-top: 30px;
    padding: 20px;
    background-color: var(--color-pink-light);
    border-radius: 10px;
}

.order-summary h3 {
    margin-bottom: 20px;
    font-size: 20px;
    color: var(--color-marron);
}

.order-summary .summary-details {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.order-summary .summary-details p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: var(--color-text-dark);
}

.order-summary .place-order{
    width: 100%;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    background: var(--color-red);
    border: none;
    outline: none;
    border-radius: 50rem;
    padding: 0.5rem 0.2rem;
}

.address-display {
    padding: 15px;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    background-color: var(--bg-light);
}
