.login{
    font-family: var(--font-family--poppins);
}
.login>div{
    padding: 1rem 0.8rem;
    border-radius: 10px;
}
.login .form-div {
    backdrop-filter: blur(10px);
}

.login .headings{
    border-radius: 50rem;
    padding: 0.5rem 0.2rem;
    margin-bottom: 0.8rem;
    color: var(--color-blue-dark);
    font-family: var(--font-family--comic-sans);
}
.login .headings h1{
    color: var(--color-red);
    font-weight: 600;

}

.login .headings p{
    font-weight: 500;
    margin-bottom: 0rem;
}
.login .input-field{
    border: 2px solid var(--color-thistle);
    /* border-radius: 5px; */
    display: flex;
    align-items: center;
    gap: 0.2rem;
    padding: 0.5rem;
    width: 80%;
    margin: auto;
    margin-bottom: 1rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.login .input-field i{
    flex-basis: 10%;
    color: var(--color-thistle);
}
.login .input-field input{
    flex-basis: 78%;
    border: none;
    outline:none;
    background: transparent;
}
.login button[type="submit"]{
    color: white;
    padding: 0.3rem 0.2rem;
    text-align: center;
    background: var(--color-blue-dark);
    text-transform: capitalize;
    width: 50%;
    margin: 1rem auto;
    border-radius: 50rem;
    font-weight: 500;
    font-size: 1.2rem;
}
.login a{
    color: var(--color-marron);
}

.flex.width-80{
    width: 90%;
    margin: auto;
}
.resend-btn a{
    text-decoration: none;
    color: inherit;
}
.resend-btn a:hover{
    color: red;
}

@media only screen and (max-width: 520px){
    .login .input-field {
        width: 95%;
        gap: 0.5rem;
    }
}
@media only screen and (max-width: 420px){
    .login .input-field {
        width: 95%;
        gap: 0.5rem;
    }
    .login button[type="submit"] {
        width: 80%;
    }
    .login {
        padding: 0.5rem;
    }
    .login .flex.width-80{
        margin-top: 0.5rem !important;
        width: 100%;
        flex-direction: column;
        gap: 1rem;
    }
}











