
.shop-container {
    display: flex;
    margin: 20px;
}

.shop-container .filter-toggle-btn {
    display: none;
    position: fixed;
    bottom: 4.2rem;
    padding: 6px 15px;
    background: var(--color-orange);
    color: #fff;
    border: none;
    border-radius: 5px;
    left: 33%;
    cursor: pointer;
    z-index: 1200;
    transition: background var(--transition-3);
}

.shop-container .filter-toggle-btn:hover {
    background: var(--color-button-hover);
}

.shop-container .filter-section {
    width: 25%;
    background: var(--linear-gradient);
    background: var(--color-pink-light);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    position: sticky;
    top: 5.5rem;
    height: fit-content;
    transition: transform 0.3s ease;
}

.shop-container .filter-section.show {
    transform: translateX(0);
}

.shop-container .filter-section.hide {
    transform: translateX(-100%);
}


.shop-container .filter-section h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: var(--color-blue-dark);
}

.shop-container .filter-group {
    margin-bottom: 20px;
}

.shop-container .filter-group h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: var(--color-blue-dark);
}

.shop-container .category-list {
    display: flex;
    flex-direction: column;
}

.shop-container .category-list label {
    margin-bottom: 5px;
}

.shop-container .price-range input[type="range"] {
    width: 100%;
}

.shop-container .price-values {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.shop-container .products-section {
    width: 100%;
}

.shop-container .products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.shop-container .product-card {
    background: #fff;
    border-radius: 10px;
    border: 1px solid var(--color-light-sky-blue);
    padding: 15px;
    text-align: center;
    transition: box-shadow var(--transition-3);
    position: relative;
    color: inherit;
    text-decoration: none;
}

.shop-container .product-card img {
    width: 100%;
    height: 16rem;
    object-fit: contain;
    border-bottom: 1px solid var(--color-light-sky-blue);
    margin-bottom: 10px;
}

.shop-container .product-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: var(--color-blue-dark);
    text-transform: capitalize;
}

.shop-container .price {
    font-size: 1.5rem;
    margin-bottom: 5px;
    color: var(--color-marron);
}

.shop-container .price .actual-price {
    text-decoration: line-through;
    margin-right: 5px;
    color: var(--color-marron);
    font-size: 1rem;
}

.shop-container .discount {
    background: var(--linear-gradient);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    padding: 3px 8px;
    position: absolute;
    right: 0;
    top: 4%;
    color: var(--color-blue-dark);
}

.shop-container .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.shop-container .page-btn {
    padding: 10px 15px;
    margin: 0 5px;
    border: 1px solid var(--color-blue-dark);
    border-radius: 5px;
    cursor: pointer;
    background: var(--color-light-sky-blue);
    color: var(--color-blue-dark);
    transition: background var(--transition-3);
}

.shop-container .page-btn:hover,
.shop-container .page-btn.active {
    background: var(--color-fairy-tale);
    color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .shop-container .products-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    .shop-container .product-card img {
        height: 12rem;
    }
}

@media (max-width: 480px) {
    .shop-container .product-card {
        padding: 0.5rem;
    }
    .shop-container .filter-toggle-btn {
        display: block;
    }
    .shop-container .product-card img {
        height: 10rem;
    }
    .shop-container .product-card h3 {
        font-size: 1rem;
    }
    .shop-container .price {
        font-size: 1.2rem;
    }
    .shop-container .price .actual-price {
        font-size: 1rem;
    }


    .shop-container .shop-container {
        flex-direction: column;
    }

    .shop-container .filter-section {
        width: 100%;
        position: fixed;
        top: 5rem;
        right: 0px;
        height: 70vh;
        overflow-y: auto;
        transform: translateX(120%);
        z-index: 1001;
    }

    .shop-container .filter-section.show {
        width: 80%;
        transform: translateX(0);
        right: 0%;
        margin-right: 0rem;
        background: var(--color-pink-light);
        border-radius: 0rem;
        height: 100vh;
    }
    

    .shop-container .filter-section.hide {
        transform: translateX(100%);
    }

    .shop-container .products-section {
        margin-top: 20px;
    }
}