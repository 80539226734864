.contact-section {
    width: 100%;
    font-family: var(--font-family--poppins);
}


/* .contact-section .contact-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 40px 60px;

    display: flex;
    flex-direction: column;
    gap: 30px;
} */

.contact-section .contact-container .first {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-section .contact-container .first .left {
    width: 47%;
}

.contact-section .contact-container .first .right {
    width: 47%;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-section .contact-container .first .right h2 {
    font-weight: 700;
    font-size: 38px;
    color: var(--color-light-sky-blue);
}

.contact-section .contact-container .first .right p span {
    font-weight: 700;
    font-size: 17px;
}

.contact-section .contact-container .first .right p {
    font-size: 15px;
}

@media (max-width:700px) {

    .contact-section .contact-container {
        padding: 40px 20px;
    }

    .contact-section .contact-container .first {
        flex-direction: column;
        gap: 20px;
    }

    .contact-section .contact-container .first .left {
        width: 100%;
    }

    .contact-section .contact-container .first .right {
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }

}

.contact-section .contact-container .second{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-section .contact-container .second .heading{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.contact-section .contact-container .second .heading h2{
    font-weight: 700;
    font-size: 30px;
    text-align: center;
}

.contact-section .contact-container .second .heading p{
    text-align: center;
    font-weight: 400;
}

.contact-section .contact-container .second form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.contact-section .contact-container .second form .same-field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-section .contact-container .second form .same-field .left {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.contact-section .contact-container label {
    color: var(--color-marron);
}

.contact-section .contact-container .second form .same-field .left label {
    font-size: 17px;
    font-weight: 700;
}

.contact-section .contact-container .second form .same-field .left input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #F5F8FA;
    border: 1px solid rgb(186, 186, 186);
    outline: none;
    padding-left: 10px;
}

.contact-section .contact-container .second form .same-field .right {
    width: 48%;
}

.contact-section .contact-container .second form .same-field .right label {
    font-size: 17px;
    font-weight: 700;
}

.contact-section .contact-container .second form .same-field .right input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #F5F8FA;
    border: 1px solid rgb(186, 186, 186);
    outline: none;
    padding-left: 10px;
}

.contact-section .contact-container .second form .formessage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
}

.contact-section .contact-container .second form .formessage label {
    font-size: 17px;
    font-weight: 700;
}

.contact-section .contact-container .second form .formessage textarea {
    width: 100%;
    height: 100px;
    border-radius: 4px;
    background-color: #F5F8FA;
    border: 1px solid rgb(186, 186, 186);
    outline: none;
    padding-left: 10px;
}

.contact-section .contact-container .second form .sendbtn{
    padding: 7px 20px;
    background-color: var(--color-light-sky-blue);
    color: white;
    font-weight: 700;
    transition: all 0.3s linear;
    font-size: 20px;
    border-radius: 3px;
    border-color: var(--color-light-sky-blue);
}

.contact-section .contact-container .second form .sendbtn:hover{
    background-color: #FAE0EE;
}

@media (max-width:650px) {
    .contact-section .contact-container .second form .same-field {
        flex-direction: column;
        gap: 10px;
    }
    
    .contact-section .contact-container .second form .same-field .left {
        width: 100%;
    }
    
    .contact-section .contact-container .second form .same-field .right {
        width: 100%;
    }

    .contact-section .contact-container .second .heading h2{
        font-weight: 700;
        font-size: 29px;
        text-align: center;
    }
    
    .contact-section .contact-container .second .heading p{
        text-align: center;
        font-weight: 400;
    }
    
}