.swiper-button-prev, .swiper-button-next {
    color: var(--color-thistle, var(--color-thistle)) !important;
}
.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 2rem !important;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -6px) !important;
}


