
.single-category {
    border-radius: 8px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 240px;
    text-decoration: none;
    color: white;
    transition: transform 0.3s ease, background-color 0.3s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    display: flex;
    align-items: flex-end;
    width: 100%;
}
.single-category .content{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* justify-content: space-between; */
    /* background-color: var(--color-thistle); */
    background: #ffe7e8;
    border-radius: 10px;
    z-index: 2;
    position: relative;
    width: 100%;
    backdrop-filter: blur( 5px );
-webkit-backdrop-filter: blur( 5px );
}
.single-category:hover {
    transform: translateY(-5px);
}


.single-category h3 {
    margin-bottom: 0rem;
    font-weight: 700;
    text-transform: capitalize;
    font-family: var(--font-family--comic-sans);
    color: var(--color-red);
    font-size: 1.3rem;
}

.single-category button {
    border: 2px solid white;
    padding: 0.2rem 0.8rem;
    background: transparent;
    color: white;
    cursor: pointer;
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    transition: background 0.3s ease, color 0.3s ease;
    border-radius: 8px;
}

.single-category button:hover {
    background: var(--color-button-hover);
    color: var(--color-blue-dark);
}


@media only screen and (max-width: 550px){
    .single-category h3 {
        font-size: 1rem;
    }
    .single-category button {
        font-size: 0.8rem;
    }
    .single-category {
        min-height: 180px;
    }
}